.PatientHistory__bg {
  background: url(../../../images/history-patientbg.jpg) no-repeat center center/cover;
}

.PatientHistory__patient {
  font-weight: 300;
  color: #fff;
  display: inline-flex;
  padding: 0.8rem 3.2rem;
  border-radius: 0.8rem 4rem 4rem 0;
  position: absolute;
  top: -0.2rem;
  left: -0.2rem;
  background: #43d9b2;
  font-size: 1.8rem;
  align-items: center;
}

.PatientHistory__patient:after {
  content: 'Historias';
  font-weight: 700;
  color: #fff;
  display: inline-block;
  padding: 0.4rem 3.2rem;
  border-radius: 0.8rem 4rem 4rem 0;
  position: absolute;
  top: 4.2rem;
  left: 0;
  background: #aa8acf;
  font-size: 1.4rem;
}

.PatientHistory__patient strong {
  font-size: 1.4rem;
  margin-left: 1rem;
}

.PatientHistory .user-info {
  left: 3rem;
  right: unset;
  top: -6rem;
  bottom: unset;
}

.PatientHistory__add-history {
  width: 5rem;
  height: 7.6rem;
  background: #43d9b2 url(../../../images/plus-white.svg) no-repeat center center/2rem;
  background-size: 2rem 2rem;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 1.6rem 1.6rem 0;
  border: 0;
  transition: all 0.3s ease-in-out;
}

.PatientHistory__add-history:hover {
  cursor: pointer;
  background: #4289dd url(../../../images/plus-white.svg) no-repeat center center/2rem;
}

.PatientHistory__body {
  padding-top: 8rem;
  position: relative;
  border-radius: 0 0 0.8rem 0.8rem;
}

.PatientHistory small {
  font-weight: 400;
  font-size: 1.4rem;
  display: table;
  color: #fff;
  background: #43d9b2;
  padding: 0.2rem 1.2rem;
  border-radius: 0 2rem 2rem 0;
  padding-left: 2rem;
}

.PatientHistory__reason, .PatientHistory__clinic, .PatientHistory__diagnostic, .PatientHistory__treatment {
  padding: 0 2rem;
  margin-bottom: 2rem;
}

.PatientHistory__date {
  position: absolute;
  top: 0;
  left: 0;
  background: #4289dd;
  color: #fff;
  width: 100%;
  padding: 0.8rem 1.6rem;
  border-radius: 0.8rem 0.8rem 0rem 0;
  margin-bottom: 0;
}

.PatientHistory__history {
  padding: 2rem 0;
  border-radius: 0.8rem;
  margin-bottom: 6rem;
  position: relative;
  padding-top: 3.9rem;
  box-shadow: 0 1.4rem 4.2rem -0.5rem rgba(42, 55, 67, 0.5);
}

.PatientHistory__addHistory {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #43d9b2;
  color: #fff;
  right: 8rem;
  border: solid 0.2rem #43d9b2;
  padding: 0.8rem 3.2rem;
  border-radius: 0.8rem 0.8rem 0 0;
  font-size: 1.6rem;
  border-bottom: 0;
}
