.Home__banner {
  position: relative;
}

.Home__h1 {
  margin-bottom: 3rem;
  margin-left: 2rem;
}

.Home__falselink {
  font-weight: 700;
  color: #43d9b2;
  margin: 0;
}

.Home__loadmore {
  font-size: 1.8rem;
  margin-top: 4rem;
  color: #fff;
  background: #43d9b2;
  display: inline-block;
  padding: 0.8rem 3.2rem;
  border-radius: 2rem;
  transition: all 0.3s ease-in-out;
}

.Home__loadmore:hover {
  cursor: pointer;
  background: #4289dd;
}

.Home__desc {
  margin-top: 1rem;
  border-top: solid 0.1rem #43d9b2;
  padding-top: 1rem;
}

.Home__img {
  overflow: hidden;
  height: 14rem;
  position: relative;
}

.Home__img img {
  height: auto;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.Home__img h2 {
  color: #fff;
  font-weight: 400;
  background: rgba(67, 217, 178, 0.9);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  z-index: 10;
  font-size: 1.4rem;
  margin: 0;
}

.Home__card {
  position: relative;
  height: 20rem;
  border-radius: 1.6rem;
  overflow: hidden;
  border-color: #43d9b2;
}

.Home__card .card-body {
  padding: 2rem;
}

.Home__card .card-body small {
  color: #4289dd;
  font-weight: 700;
}

.Home__card .card-body small.text-muted {
  color: #aa8acf !important;
  display: block;
  font-size: 1rem;
}

.Home__link {
  text-decoration: none;
  color: #2a3743;
}

.Home__link:hover {
  color: #2a3743;
}

.Home__link:hover img {
  transform: scale(1.2);
}

@media (max-width: 576px) {
  .Home__banner {
    border-radius: 0;
  }
  .Home .login-block {
    padding: 2rem 1rem;
  }
}
