@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
html {
  font-size: 10px;
}

body {
  font-size: 1.4rem;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #2a3743;
}

body strong {
  font-weight: 700;
}

body .light {
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

.gray-bg {
  background: #f3f6f8;
}

.loader {
  background: url(/static/media/loader.16cb82e0.gif) no-repeat left center/contain;
  padding-left: 8rem;
  height: 8rem;
  display: flex;
  align-items: center;
}

.loader span {
  color: #2a3743;
  margin-left: 1rem;
}

.Footer {
  border-top: solid 0.1rem #43d9b2;
  color: #fff;
  font-size: 1.2rem;
  padding: 1rem;
  margin-top: 3rem;
  background: linear-gradient(to right, #43d9b2, #4289dd);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20;
}

.Footer__link {
  text-align: right;
}

.Footer__link a {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 576px) {
  .Footer, .Footer__link {
    text-align: center;
  }
}

.Header__navbar {
  font-weight: 400;
  align-items: center;
  width: 100%;
  padding: 0;
  padding-right: 2rem;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
}

.Header__navbar .navbar-nav {
  float: right;
}

.Header__navbar .logout {
  font-weight: 700;
  position: relative;
  display: flex;
  align-items: center;
  color: #43d9b2 !important;
}

.Header__navbar .logout:after {
  content: '';
  position: absolute;
  right: -2.4rem;
  background: url(/static/media/exit.6bc05d9e.svg) no-repeat center center;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  transition: all 200ms ease-in-out;
}

.Header__navbar .logout:hover:after {
  margin-left: 1.6rem;
  right: -2.8rem;
}

.Header__navbar .user-logs {
  border-left: 0;
}

.Header__navbar .header-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Header__navbar .header-user .user-hi {
  color: #fff;
  border-left: solid 0.1rem #4289dd;
  padding-left: 2rem;
}

.Header__navbar .navbar-brand {
  padding: 3rem 1rem 3rem 3rem;
}

.Header__navbar .navbar-brand a {
  text-decoration: none;
}

.Header__navbar .navbar-brand h1 {
  color: #2a3743;
}

.Header__navbar .navbar-brand .logo-header {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 3rem;
  text-transform: uppercase;
  color: #43d9b2;
}

.Header__navbar .navbar-brand .logo-header:before {
  content: '';
  display: inline-block;
  background: url(/static/media/icon-logo.0449c8a6.svg) no-repeat center center/contain;
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 1.6rem;
}

.Header__navbar .navbar-brand .logo-header:after {
  content: 'Oliver';
  display: block;
  font-weight: 300;
  color: #4289dd;
  margin-left: 0.8rem;
  position: absolute;
  left: 4rem;
  top: 2.4rem;
}

.Header__navbar .navbar-collapse {
  justify-content: flex-end;
}

.Header__navbar .navbar-collapse ul {
  justify-content: flex-end;
}

.Header__navbar .nav-item {
  margin-right: 3rem;
  display: inline-block;
}

.Header__navbar .nav-item a {
  color: rgba(42, 55, 67, 0.6);
  transition: all 200ms ease;
}

.Header__navbar .nav-item a.login {
  background: #43d9b2;
  color: #fff;
  padding: 0.8rem 3.2rem;
  border-radius: 4rem;
  position: relative;
  margin-left: 3rem;
}

.Header__navbar .nav-item a.login:hover, .Header__navbar .nav-item a.login.active {
  color: #fff;
  background: #4289dd;
}

.Header__navbar .nav-item a.login.active {
  pointer-events: none;
}

.Header__navbar .nav-item a:hover {
  color: #4289dd;
}

.Header__navbar .nav-item .active {
  color: #4289dd;
}

@media (max-width: 576px) {
  .Header__navbar {
    padding: 0;
    padding-right: 0;
    margin-bottom: 0;
    position: relative;
  }
  .Header__navbar .navbar-brand {
    padding: 0 0 4rem 2rem;
  }
  .Header .resp_login {
    color: #43d9b2;
    text-decoration: none;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: #43d9b2 url(/static/media/sign-in.d0f1bc4f.svg) no-repeat center center/2rem;
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}

.login.head-bg {
  background: url(/static/media/login-bg.ec9d3623.jpg) no-repeat center bottom/cover;
}

.login-block {
  background: rgba(255, 255, 255, 0.95);
  padding: 6rem;
  align-self: center;
  box-shadow: 0px 14px 42px -5px rgba(0, 0, 0, 0.5);
  border-radius: 1.6rem;
  margin-top: -10rem;
  margin-bottom: 10rem;
  z-index: 6;
}

.login-block h1 {
  margin-bottom: 2rem;
  margin-top: 0;
}

.alert.alert-danger {
  background: none;
  border: 0;
  color: #e96571;
  margin: 2rem auto;
  text-align: center;
}

.Login__options {
  margin: 2rem auto 0 auto;
  border-top: solid 0.1rem rgba(42, 55, 67, 0.1);
  padding-top: 2rem;
}

.Login__options a {
  color: #2a3743;
}

.Login__options a:first-child {
  border-right: solid 0.1rem #2a3743;
  padding-right: 1rem;
  margin-right: 1rem;
  color: #43d9b2;
}

.Login__img {
  width: 2rem;
  margin-right: 1rem;
}

.Login__google {
  background: #e96571;
  color: #fff;
  border-radius: 1.6rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  font-size: 1.6rem;
  transition: all 0.3s ease;
}

.Login__google:hover {
  cursor: pointer;
  filter: brightness(1.1);
}

.Login__form {
  border-bottom: solid 0.1rem rgba(42, 55, 67, 0.1);
  padding-bottom: 2rem;
}

@media (max-width: 576px) {
  .login-block {
    padding: 3rem;
  }
  .options a:first-child {
    border-right: 0;
  }
}

.form-group {
  margin-top: 2rem;
}

.form-group .label {
  text-transform: none;
  margin-bottom: 1rem;
}

.form-group .form-control {
  background: #fff;
  font-size: 1.6rem;
  border: solid 0.1rem rgba(66, 137, 221, 0.5);
  border-radius: 0.8rem;
  height: 4rem;
  margin-bottom: 2rem;
}

.invalid-feedback {
  color: #e96571;
  font-size: 1.2rem;
}

.hidden {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.Button {
  padding: 0.8rem 3.2rem;
  margin: auto;
  display: block;
  transition: all 200ms ease;
  border-radius: 1.6rem;
}

.Button__enter {
  margin-top: 2rem;
  background: #43d9b2;
  border: 0;
  color: #fff;
  padding: 1.2rem;
  width: 100%;
  font-size: 1.8rem;
}

.Button:hover {
  filter: brightness(1.1);
}

button:disabled {
  opacity: 0.3;
  background: #43d9b2;
}

.checkbox-label {
  margin-top: 2rem;
}

.user-info {
  background: #fff;
  padding: 1rem;
  padding-left: 6.4rem;
  margin-top: 2rem;
  border-radius: 1.6rem;
  position: absolute;
  bottom: -3rem;
  right: 3rem;
  box-shadow: 0px 14px 42px -5px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.user-info .user-info-row {
  display: flex;
  align-items: center;
  position: relative;
}

.user-info .user-info-row .name h1 {
  color: #aa8acf;
  font-weight: 300;
}

.user-info .user-info-row strong {
  color: #443754;
}

.user-info .user-profile {
  width: 5rem;
  height: 7.6rem;
  background: #aa8acf url(/static/media/user-profile.a641f6a4.svg) no-repeat center center/contain;
  background-size: 2rem 2rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1.6rem 0 0 1.6rem;
  border: 0;
}

.head-bg {
  height: 30rem;
  background: url(/static/media/page-bg.6f04a98b.jpg) no-repeat center center/cover;
  border-radius: 1.6rem;
  position: relative;
}

.custom-card {
  background: #fff;
  margin: 1rem 0;
}

.custom-card .custom-info {
  padding: 2rem 0;
}

.custom-card .custom-info span {
  color: #43d9b2;
}

.custom-card .custom-info .card {
  font-size: 1.6rem;
  border-radius: 0.8rem;
  border: 0;
  box-shadow: 0px 14px 42px -5px rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem;
}

.custom-card .custom-info .card .card-body {
  position: relative;
  padding-top: 8rem;
  font-size: 1.4rem;
}

.custom-card .custom-info .card .card-body .biopsia,
.custom-card .custom-info .card .card-body .biopsy-date {
  position: absolute;
  color: white;
  padding: 0.2rem 1.6rem;
  padding-bottom: 0.6rem;
  border-radius: 0 0.8rem 0.8rem 0;
  left: 0;
  font-size: 1.2rem;
}

.custom-card .custom-info .card .card-body .biopsia {
  top: 0;
  background: #aa8acf;
  border-radius: 0.8rem 0.8rem 0.8rem 0;
}

.custom-card .custom-info .card .card-body .biopsy-date {
  background: #43d9b2;
  display: inline-block;
  top: 2.4rem;
}

.custom-card .custom-info .card .card-body .card-text {
  margin-bottom: 2rem;
}

.custom-card .custom-info .card .card-body .card-text:last-child {
  margin-bottom: 0;
}

.custom-card .custom-info .card .card-footer {
  background: #43d9b2;
  border: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 0 0 0.8rem 0.8rem;
}

.custom-card .custom-info .patient-name {
  padding: 0.2rem 1.6rem;
  border-radius: 0 0.8rem 0.8rem 0;
  color: #fff;
  font-size: 1.2rem;
  position: absolute;
  left: 0;
}

.custom-card .custom-info .patient-name {
  top: 2.9rem;
  background: #92b8eb;
}

.custom-card .custom-info .patient-name span {
  color: #fff;
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: solid 0.1rem #fff;
}

.doctor-icon,
.cal-icon,
.micro-icon,
.scalpel-icon,
.note-icon,
.search-icon {
  position: relative;
}

.doctor-icon:before,
.cal-icon:before,
.micro-icon:before,
.scalpel-icon:before,
.note-icon:before,
.search-icon:before {
  content: '';
  width: 2rem;
  height: 5rem;
  margin-right: 1.6rem;
  float: left;
}

.doctor-icon:before {
  background: url(/static/media/doctor.1668aaaf.svg) no-repeat center center/contain;
}

.cal-icon:before {
  background: url(/static/media/calendar.34dd111c.svg) no-repeat center center/contain;
}

.micro-icon:before {
  background: url(/static/media/microscope.1de3f7aa.svg) no-repeat center center/contain;
}

.scalpel-icon:before {
  background: url(/static/media/scalpel.3d0169bd.svg) no-repeat center center/contain;
}

.note-icon:before {
  background: url(/static/media/note.ff7573a5.svg) no-repeat center center/contain;
}

.search-icon:before {
  background: url(/static/media/search-icon.bf953329.svg) no-repeat center center/contain;
  position: absolute;
  top: 50%;
  margin-top: -2.5rem;
  left: 1.6rem;
  z-index: 0;
}

.search-icon .form-control {
  padding-left: 3.6rem;
  background: transparent;
  border: 0;
}

.plus-icon:after {
  content: '';
  width: 2rem;
  height: 2rem;
  display: inline-block;
  margin-left: 1rem;
}

.plus-icon:after {
  background: url(/static/media/plus-white.4c487db4.svg) no-repeat center center/contain;
}

.gray-bg {
  border-radius: 1.6rem;
}

.gray-bg .form-control {
  margin-bottom: 0;
}

.form-group {
  margin-top: 0rem;
}

.title {
  margin-top: 6rem;
}

.title span {
  font-weight: 300;
}

@media (max-width: 576px) {
  .user-info {
    left: 1rem;
    right: 1rem;
  }
  .user-info .user-profile {
    height: 6.9rem;
  }
  .head-bg {
    height: 20rem;
    border-radius: 0;
  }
}

.primary, .secondary {
  border: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 3.2rem;
  border-radius: 4rem;
  font-size: 1.4rem;
}

.primary {
  background: #43d9b2;
}

.secondary {
  background: #e96571;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal .icon:before {
  content: '';
  width: 6rem;
  height: 6rem;
  background: url(/static/media/biopsy.bd74f920.svg) no-repeat center center;
  float: left;
  margin-right: 1rem;
}

.modal .b-number {
  color: #fff;
  font-weight: 700;
  background: #43d9b2;
  float: left;
  padding: 0 1.6rem;
  border-radius: 4rem;
}

.modal .b-number,
.modal .b-date {
  font-size: 1.6rem;
}

.modal .patient-info {
  background: #43d9b2;
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 4rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: 1.6rem;
}

.modal hr {
  margin-top: 2rem;
}

.modal .discipline {
  font-weight: 400;
  color: rgba(51, 51, 51, 0.6);
  font-size: 1.8rem;
}

.modal .modal-body {
  background: #fff;
  padding: 3rem;
  border-radius: 0.8rem;
  position: relative;
  border: solid 0.2rem #43d9b2;
  flex: 0 0 auto;
}

.modal .modal-body button {
  margin: 3rem auto 0 auto;
}

.modal .modal-body span {
  color: #43d9b2;
}

.modal .modal-body span h1 {
  color: #2a3743;
}

.modal .modal-body .btn-row {
  display: flex;
  justify-content: center;
}

.modal .modal-body .btn-row .primary {
  display: inline-block;
  margin: 2rem auto 0 auto;
  transition: all 200ms ease-in-out;
}

.modal .modal-body .btn-row .primary:hover {
  color: #fff;
  text-decoration: none;
  background: rgba(67, 217, 178, 0.8);
}

.modal .close {
  position: absolute;
  font-size: 2rem;
  top: 2rem;
  right: 2rem;
  width: 3rem;
  height: 3rem;
  font-weight: 300;
  background: url(/static/media/close.41e117c7.svg) no-repeat center center/contain;
  z-index: 5;
}

.modal .close:hover {
  cursor: pointer;
}

.modal .downloadPDF {
  position: relative;
  padding-left: 5rem;
}

.modal .downloadPDF:before {
  content: '';
  background-image: url(/static/media/download.e6e13002.svg);
  display: inline-block;
  margin-right: 1rem;
  position: absolute;
  width: 3rem;
  height: 3rem;
  background-size: 2rem 2rem;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  left: 1rem;
  top: 0.5rem;
  border-radius: 50%;
}

.modal .edit,
.modal .delete {
  display: flex;
  justify-content: center;
  transition: all 200ms ease-in-out;
  position: relative;
}

.modal .edit:before,
.modal .delete:before {
  content: '';
  display: inline-block;
  margin-right: 1rem;
  position: absolute;
  width: 3rem;
  height: 3rem;
  background-size: 2rem 2rem;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  left: 1rem;
  border-radius: 50%;
}

.modal .edit:before {
  background-image: url(/static/media/edit.5841b2f0.svg);
}

.modal .edit:hover {
  color: #fff;
  text-decoration: none;
  background: rgba(67, 217, 178, 0.8);
}

.modal .delete:before {
  background-image: url(/static/media/delete.9a2fe0fe.svg);
}

.modal .delete:hover {
  color: #fff;
  text-decoration: none;
  background: rgba(233, 101, 113, 0.8);
}

.modal hr {
  margin-bottom: 1rem;
}

@media (max-width: 576px) {
  .modal .modal-body {
    padding: 2rem 1rem;
  }
  .modal .icon:before {
    width: 3rem;
    height: 3rem;
  }
  .modal .patient-info {
    color: #43d9b2;
    border-radius: 0;
    background: #fff;
    border-top: solid 0.1rem #43d9b2;
    border-bottom: solid 0.1rem #43d9b2;
  }
  .modal .patient-info .text-center {
    text-align: left !important;
  }
}

.EditModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.EditModal__body {
  background: #fff;
  padding: 3rem;
  height: 90vh;
  overflow: scroll;
  position: relative;
  border: solid 0.2rem #43d9b2;
  display: flex;
  align-items: center;
  border-radius: 0.8rem;
}

.EditModal__body h1 {
  position: relative;
}

.EditModal__body h1 span {
  margin-top: 0.8rem;
  color: #fff;
  font-size: 1.4rem;
  display: table;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  margin-bottom: 3rem;
  background: #43d9b2;
}

.EditModal__body h1:before {
  content: '';
  width: 6rem;
  height: 6rem;
  background: url(/static/media/biopsy.bd74f920.svg) no-repeat center center;
  float: left;
  margin-right: 1rem;
}

.close {
  position: absolute;
  font-size: 2rem;
  top: 2rem;
  right: 2rem;
  width: 3rem;
  height: 3rem;
  font-weight: 300;
  background: url(/static/media/close.41e117c7.svg) no-repeat center center/contain;
  z-index: 5;
}

.close:hover {
  cursor: pointer;
}

.biopsy-bg {
  height: 30rem;
  background: url(/static/media/fondo-biopsy.ac4f44f3.jpg) no-repeat bottom center/cover;
  border-radius: 1.6rem;
  position: relative;
}

.biopsy-bg .user-info {
  bottom: 6rem;
  right: 10rem;
  z-index: 20;
}

.tox .tox-statusbar {
  display: none !important;
}

.new-biopsy label {
  margin-bottom: 1rem;
}

.new-biopsy .tox {
  margin-bottom: 3rem;
}

.patients .message {
  margin-top: 6rem;
  padding: 1rem 2rem;
  background: rgba(170, 138, 207, 0.3);
  border: solid 0.1rem #aa8acf;
  color: #aa8acf;
}

.patients .custom-info .patient-name {
  top: 0;
}

.patients .custom-info .card .card-body {
  padding: 4rem 0 0 0;
  font-size: 1.4rem;
}

.patients .custom-info .card .card-body .card-text .patient-dni {
  border-left: solid 0.1rem #fff;
  color: #fff;
  margin-left: 0.8rem;
  padding-left: 0.8rem;
}

.patients .email-icon,
.patients .phone-icon,
.patients .address-icon,
.patients .calendar-icon {
  position: relative;
  padding: 0 1rem;
}

.patients .email-icon:before,
.patients .phone-icon:before,
.patients .address-icon:before,
.patients .calendar-icon:before {
  content: '';
  width: 2rem;
  height: 5rem;
  margin-right: 1.6rem;
  float: left;
}

.patients .email-icon:before {
  background: url(/static/media/email.087a959d.svg) no-repeat center center/contain;
}

.patients .phone-icon:before {
  background: url(/static/media/phone.fb2b01d7.svg) no-repeat center center/contain;
}

.patients .address-icon:before {
  background: url(/static/media/address.84cd4519.svg) no-repeat center center/contain;
}

.patients .calendar-icon {
  border-top: solid 0.1rem #43d9b2;
  padding: 1rem;
  background: rgba(67, 217, 178, 0.16);
}

.patients .calendar-icon:before {
  background: url(/static/media/calendar.34dd111c.svg) no-repeat center center/contain;
}

.patients .calendar-icon .next-date {
  position: absolute;
  top: -1rem;
  left: 0;
  background: #c0c8cf;
  padding: 0.3rem 1rem 0.3rem 2rem;
  border-radius: 0 1rem 1rem 0;
  color: #fff;
  font-size: 1.2rem;
}

.patients .card-footer {
  padding: 0;
}

.patients .card-footer .purple-bg {
  background: #aa8acf;
  justify-content: center;
  display: flex;
  margin-bottom: 0;
}

.patients .card-footer .purple-bg .secondary {
  background: #aa8acf;
}

.patients .icons [class*='-icon'] {
  border: 0;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 4rem;
  margin-right: 1rem;
}

.patients .icons [class*='-icon']:hover {
  opacity: 0.5;
}

.patients .icons .edit-icon {
  background: #43d9b2 url(/static/media/edit_white.7b272bce.svg) no-repeat center center/contain;
  background-size: 1.2rem;
}

.patients .icons .delete-icon {
  background: #e96571 url(/static/media/delete_white.272b6be2.svg) no-repeat center center/contain;
  margin-top: 0.5rem;
  background-size: 1.2rem;
}

.patients .icons .position-absolute {
  top: 0.5rem;
  right: 0.5rem;
}

.primary {
  text-decoration: none;
}

.primary:hover {
  color: #fff;
  font-weight: 700;
}

.AddDateModal .form-group label {
  display: none;
}

.AddDateModal .modal-body {
  border: 0;
  box-shadow: 0 1.4rem 4.2rem -0.5rem rgba(0, 0, 0, 0.5);
  padding-top: 6rem;
}

.AddDateModal .modal-body .react-datetime-picker {
  text-align: center;
  display: flex;
  margin-top: 3rem;
}

.AddDateModal .modal-body .react-datetime-picker span {
  color: #2a3743;
}

.AddDateModal .modal-body .react-datetime-picker__clear-button, .AddDateModal .modal-body .react-datetime-picker__calendar-button {
  display: none;
}

.AddDateModal .modal-body .react-datetime-picker__wrapper {
  border: 0;
}

.AddDateModal .modal-body .user-info {
  bottom: unset;
  top: -6rem;
  right: unset;
}

.AddDateModal .modal-body .Button__enter {
  background: #aa8acf;
}

.AddDateModal .modal-body .Button__enter:hover {
  background: #43d9b2;
}

.AddDateModal.close-modal {
  animation: fadeout 200ms ease-out normal forwards;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

h3 {
  color: #43d9b2;
}

.biopsy-bg {
  height: 30rem;
  background: url(/static/media/new-patient.2296898c.jpg) no-repeat center center/cover;
  border-radius: 1.6rem;
  position: relative;
}

.biopsy-bg .user-info {
  bottom: 6rem;
  right: 10rem;
  z-index: 20;
}

.tox .tox-statusbar {
  display: none !important;
}

.new-patient label {
  margin-bottom: 1rem;
}

.new-patient .tox {
  margin-bottom: 3rem;
}

/* No CSS */
/* No CSS */
.NewBlog__progress {
  -webkit-appearance: none;
          appearance: none;
  width: 100%;
  margin: 1rem auto;
}

input {
  overflow: hidden;
}

.custom-file-input {
  display: block;
  background: #fff;
  font-size: 1.6rem;
  border: solid 0.1rem rgba(66, 137, 221, 0.5);
  border-radius: 0.8rem;
  height: 4rem;
  margin-bottom: 2rem;
  width: 100%;
}

.custom-file-input::-webkit-file-upload-button {
  display: none;
}

.custom-file-input:before {
  content: 'Selecciona una imagen';
  display: inline-flex;
  align-content: center;
  padding: 0 2rem;
  padding-top: 0.6rem;
  background: #43d9b2;
  border: 0;
  border-radius: 0.8rem 0 0 0.8rem;
  height: 4rem;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  color: #fff;
  margin-right: 1rem;
}

.Home__banner {
  position: relative;
}

.Home__h1 {
  margin-bottom: 3rem;
  margin-left: 2rem;
}

.Home__falselink {
  font-weight: 700;
  color: #43d9b2;
  margin: 0;
}

.Home__loadmore {
  font-size: 1.8rem;
  margin-top: 4rem;
  color: #fff;
  background: #43d9b2;
  display: inline-block;
  padding: 0.8rem 3.2rem;
  border-radius: 2rem;
  transition: all 0.3s ease-in-out;
}

.Home__loadmore:hover {
  cursor: pointer;
  background: #4289dd;
}

.Home__desc {
  margin-top: 1rem;
  border-top: solid 0.1rem #43d9b2;
  padding-top: 1rem;
}

.Home__img {
  overflow: hidden;
  height: 14rem;
  position: relative;
}

.Home__img img {
  height: auto;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.Home__img h2 {
  color: #fff;
  font-weight: 400;
  background: rgba(67, 217, 178, 0.9);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  z-index: 10;
  font-size: 1.4rem;
  margin: 0;
}

.Home__card {
  position: relative;
  height: 20rem;
  border-radius: 1.6rem;
  overflow: hidden;
  border-color: #43d9b2;
}

.Home__card .card-body {
  padding: 2rem;
}

.Home__card .card-body small {
  color: #4289dd;
  font-weight: 700;
}

.Home__card .card-body small.text-muted {
  color: #aa8acf !important;
  display: block;
  font-size: 1rem;
}

.Home__link {
  text-decoration: none;
  color: #2a3743;
}

.Home__link:hover {
  color: #2a3743;
}

.Home__link:hover img {
  transform: scale(1.2);
}

@media (max-width: 576px) {
  .Home__banner {
    border-radius: 0;
  }
  .Home .login-block {
    padding: 2rem 1rem;
  }
}

.BlogSingle__h1 {
  color: #43d9b2;
  border-top: solid 0.1rem #43d9b2;
  padding-top: 1rem;
}

.BlogSingle .login-block {
  background: #fff;
}

.BlogSingle .login-block h1 {
  margin-bottom: 4rem;
  margin-top: 0;
}

.BlogSingle__desc img {
  width: 100%;
  height: auto;
  border-radius: 1.6rem;
}

.BlogSingle__author {
  color: #4289dd;
  display: block;
  margin-bottom: 1rem;
}

.BlogSingle__date {
  color: #aa8acf;
  font-size: 1rem;
  font-weight: 700;
  display: block;
}

@media (max-width: 576px) {
  .BlogSingle .login-block {
    padding: 2rem;
  }
}

.NotFoundPage__h1 {
  color: #43d9b2;
  font-size: 6rem;
}

.NotFoundPage__btn {
  background: #43d9b2;
  color: #fff;
  text-decoration: none;
  padding: 0.8rem 3.2rem;
  border-radius: 2rem;
  margin-top: 3rem;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.NotFoundPage__btn:hover {
  background: #4289dd;
  color: #fff;
}

.PatientHistory__bg {
  background: url(/static/media/history-patientbg.db2b6d23.jpg) no-repeat center center/cover;
}

.PatientHistory__patient {
  font-weight: 300;
  color: #fff;
  display: inline-flex;
  padding: 0.8rem 3.2rem;
  border-radius: 0.8rem 4rem 4rem 0;
  position: absolute;
  top: -0.2rem;
  left: -0.2rem;
  background: #43d9b2;
  font-size: 1.8rem;
  align-items: center;
}

.PatientHistory__patient:after {
  content: 'Historias';
  font-weight: 700;
  color: #fff;
  display: inline-block;
  padding: 0.4rem 3.2rem;
  border-radius: 0.8rem 4rem 4rem 0;
  position: absolute;
  top: 4.2rem;
  left: 0;
  background: #aa8acf;
  font-size: 1.4rem;
}

.PatientHistory__patient strong {
  font-size: 1.4rem;
  margin-left: 1rem;
}

.PatientHistory .user-info {
  left: 3rem;
  right: unset;
  top: -6rem;
  bottom: unset;
}

.PatientHistory__add-history {
  width: 5rem;
  height: 7.6rem;
  background: #43d9b2 url(/static/media/plus-white.4c487db4.svg) no-repeat center center/2rem;
  background-size: 2rem 2rem;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 1.6rem 1.6rem 0;
  border: 0;
  transition: all 0.3s ease-in-out;
}

.PatientHistory__add-history:hover {
  cursor: pointer;
  background: #4289dd url(/static/media/plus-white.4c487db4.svg) no-repeat center center/2rem;
}

.PatientHistory__body {
  padding-top: 8rem;
  position: relative;
  border-radius: 0 0 0.8rem 0.8rem;
}

.PatientHistory small {
  font-weight: 400;
  font-size: 1.4rem;
  display: table;
  color: #fff;
  background: #43d9b2;
  padding: 0.2rem 1.2rem;
  border-radius: 0 2rem 2rem 0;
  padding-left: 2rem;
}

.PatientHistory__reason, .PatientHistory__clinic, .PatientHistory__diagnostic, .PatientHistory__treatment {
  padding: 0 2rem;
  margin-bottom: 2rem;
}

.PatientHistory__date {
  position: absolute;
  top: 0;
  left: 0;
  background: #4289dd;
  color: #fff;
  width: 100%;
  padding: 0.8rem 1.6rem;
  border-radius: 0.8rem 0.8rem 0rem 0;
  margin-bottom: 0;
}

.PatientHistory__history {
  padding: 2rem 0;
  border-radius: 0.8rem;
  margin-bottom: 6rem;
  position: relative;
  padding-top: 3.9rem;
  box-shadow: 0 1.4rem 4.2rem -0.5rem rgba(42, 55, 67, 0.5);
}

.PatientHistory__addHistory {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #43d9b2;
  color: #fff;
  right: 8rem;
  border: solid 0.2rem #43d9b2;
  padding: 0.8rem 3.2rem;
  border-radius: 0.8rem 0.8rem 0 0;
  font-size: 1.6rem;
  border-bottom: 0;
}

.HistModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(67, 217, 178, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
}

.HistModal__body {
  background: #fff;
  padding: 3rem;
  height: 90vh;
  overflow: scroll;
  position: relative;
  border: solid 0.2rem #43d9b2;
  display: flex;
  align-items: center;
  border-radius: 0.8rem;
}

.HistModal__body h1 {
  position: relative;
}

.HistModal__body h1 span {
  margin-top: 0.8rem;
  color: #fff;
  font-size: 1.4rem;
  display: table;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  margin-bottom: 3rem;
  background: #43d9b2;
}

.HistModal__body h1:before {
  content: '';
  width: 6rem;
  height: 6rem;
  background: url(/static/media/biopsy.bd74f920.svg) no-repeat center center;
  float: left;
  margin-right: 1rem;
}

.label {
  display: block;
}

.react-datetime-picker {
  width: 100%;
  border: solid 0.1rem rgba(66, 137, 221, 0.5);
  border-radius: 0.8rem;
  padding: 0.5rem;
}

.react-datetime-picker .react-datetime-picker__wrapper {
  border: none;
}

.react-datetime-picker .react-datetime-picker__calendar {
  z-index: 2;
}

.close {
  position: absolute;
  font-size: 2rem;
  top: 2rem;
  right: 2rem;
  width: 3rem;
  height: 3rem;
  font-weight: 300;
  background: url(/static/media/close.41e117c7.svg) no-repeat center center/contain;
  z-index: 5;
}

.close:hover {
  cursor: pointer;
}

.user-info {
  background: #fff;
  padding: 1rem;
  padding-left: 6.4rem;
  margin-top: 2rem;
  border-radius: 1.6rem;
  position: absolute;
  bottom: -3rem;
  right: 3rem;
  box-shadow: 0px 14px 42px -5px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.user-info .user-info-row {
  display: flex;
  align-items: center;
  position: relative;
}

.user-info .user-info-row .name h1 {
  color: #aa8acf;
  font-weight: 300;
}

.user-info .user-info-row strong {
  color: #443754;
}

.user-info .user-profile {
  width: 5rem;
  height: 7.6rem;
  background: #aa8acf url(/static/media/user-profile.a641f6a4.svg) no-repeat center center/contain;
  background-size: 2rem 2rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 1.6rem 0 0 1.6rem;
  border: 0;
}

.head-bg {
  height: 30rem;
  background: url(/static/media/page-bg.6f04a98b.jpg) no-repeat center center/cover;
  border-radius: 1.6rem;
  position: relative;
}

.custom-card {
  background: #fff;
  margin: 1rem 0;
}

.custom-card .custom-info {
  padding: 2rem 0;
}

.custom-card .custom-info span {
  color: #43d9b2;
}

.custom-card .custom-info .card {
  font-size: 1.6rem;
  border-radius: 0.8rem;
  border: 0;
  box-shadow: 0px 14px 42px -5px rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem;
}

.custom-card .custom-info .card .card-body {
  position: relative;
  padding-top: 8rem;
  font-size: 1.4rem;
}

.custom-card .custom-info .card .card-body .biopsia,
.custom-card .custom-info .card .card-body .biopsy-date {
  position: absolute;
  color: white;
  padding: 0.2rem 1.6rem;
  padding-bottom: 0.6rem;
  border-radius: 0 0.8rem 0.8rem 0;
  left: 0;
  font-size: 1.2rem;
}

.custom-card .custom-info .card .card-body .biopsia {
  top: 0;
  background: #aa8acf;
  border-radius: 0.8rem 0.8rem 0.8rem 0;
}

.custom-card .custom-info .card .card-body .biopsy-date {
  background: #43d9b2;
  display: inline-block;
  top: 2.4rem;
}

.custom-card .custom-info .card .card-body .card-text {
  margin-bottom: 2rem;
}

.custom-card .custom-info .card .card-body .card-text:last-child {
  margin-bottom: 0;
}

.custom-card .custom-info .card .card-footer {
  background: #43d9b2;
  border: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 0 0 0.8rem 0.8rem;
}

.custom-card .custom-info .patient-name {
  padding: 0.2rem 1.6rem;
  border-radius: 0 0.8rem 0.8rem 0;
  color: #fff;
  font-size: 1.2rem;
  position: absolute;
  left: 0;
}

.custom-card .custom-info .patient-name {
  top: 2.9rem;
  background: #92b8eb;
}

.custom-card .custom-info .patient-name span {
  color: #fff;
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: solid 0.1rem #fff;
}

.doctor-icon,
.cal-icon,
.micro-icon,
.scalpel-icon,
.note-icon,
.search-icon {
  position: relative;
}

.doctor-icon:before,
.cal-icon:before,
.micro-icon:before,
.scalpel-icon:before,
.note-icon:before,
.search-icon:before {
  content: '';
  width: 2rem;
  height: 5rem;
  margin-right: 1.6rem;
  float: left;
}

.doctor-icon:before {
  background: url(/static/media/doctor.1668aaaf.svg) no-repeat center center/contain;
}

.cal-icon:before {
  background: url(/static/media/calendar.34dd111c.svg) no-repeat center center/contain;
}

.micro-icon:before {
  background: url(/static/media/microscope.1de3f7aa.svg) no-repeat center center/contain;
}

.scalpel-icon:before {
  background: url(/static/media/scalpel.3d0169bd.svg) no-repeat center center/contain;
}

.note-icon:before {
  background: url(/static/media/note.ff7573a5.svg) no-repeat center center/contain;
}

.search-icon:before {
  background: url(/static/media/search-icon.bf953329.svg) no-repeat center center/contain;
  position: absolute;
  top: 50%;
  margin-top: -2.5rem;
  left: 1.6rem;
  z-index: 0;
}

.search-icon .form-control {
  padding-left: 3.6rem;
  background: transparent;
  border: 0;
}

.plus-icon:after {
  content: '';
  width: 2rem;
  height: 2rem;
  display: inline-block;
  margin-left: 1rem;
}

.plus-icon:after {
  background: url(/static/media/plus-white.4c487db4.svg) no-repeat center center/contain;
}

.gray-bg {
  border-radius: 1.6rem;
}

.gray-bg .form-control {
  margin-bottom: 0;
}

.form-group {
  margin-top: 0rem;
}

.title {
  margin-top: 6rem;
}

.title span {
  font-weight: 300;
}

.Casos__card {
  border-radius: 0.8rem;
  box-shadow: 0px 14px 42px -5px rgba(42, 55, 67, 0.5);
}

.Casos__footer {
  margin-bottom: 1rem;
  padding-top: 1rem;
  display: flex;
  justify-content: space-around;
  border-top: 0.1rem solid rgba(42, 55, 67, 0.1);
}

.Casos__edit, .Casos__delete {
  border: 0;
  padding: 0.8rem 2.2rem 0.8rem 4rem;
  color: #fff;
  border-radius: 2rem;
  text-align: center;
  transition: all 0.2s ease-in-out;
}

.Casos__edit:hover, .Casos__delete:hover {
  filter: brightness(1.1);
}

.Casos__edit {
  background: #43d9b2 url(/static/media/edit_white.7b272bce.svg) no-repeat 1.8rem center/1.6rem;
}

.Casos__delete {
  background: #e96571 url(/static/media/delete_white.272b6be2.svg) no-repeat 1.8rem center/1.6rem;
}

@media (max-width: 576px) {
  .user-info {
    left: 1rem;
    right: 1rem;
  }
  .user-info .user-profile {
    height: 6.9rem;
  }
  .head-bg {
    height: 20rem;
    border-radius: 0;
  }
}

.ModalPrev {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(67, 217, 178, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 30;
}

.ModalPrev__body {
  border: solid 0.1rem #43d9b2;
  border-radius: 0.8rem;
  background: #fff;
  padding: 3rem;
  position: relative;
}

.ModalPrev__body:before {
  content: '';
  width: 100.9%;
  height: 109%;
  position: absolute;
  top: -4%;
  left: -0.4%;
  border: solid 0.2rem #fff;
  border-radius: 0.8rem;
  z-index: -1;
}

.ModalPrev__delete, .ModalPrev__cancel {
  border: 0;
  padding: 0rem 3.2rem;
  border-radius: 4rem;
  height: 4rem;
  color: #fff;
  transition: all 0.2s ease-in-out;
}

.ModalPrev__delete:hover, .ModalPrev__cancel:hover {
  cursor: pointer;
  filter: brightness(1.1);
}

.ModalPrev__delete {
  background: #e96571;
}

.ModalPrev__cancel {
  background: #43d9b2;
}

.EditCaseModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
}

.EditCaseModal__body {
  background: #fff;
  padding: 6rem 3rem;
  overflow: scroll;
  position: relative;
  border: solid 0.2rem #43d9b2;
  border-radius: 0.8rem;
}

.EditCaseModal__body h1 {
  position: relative;
  margin-bottom: 2rem;
}

.close {
  position: absolute;
  font-size: 2rem;
  top: 2rem;
  right: 2rem;
  width: 3rem;
  height: 3rem;
  font-weight: 300;
  background: url(/static/media/close.41e117c7.svg) no-repeat center center/contain;
  z-index: 5;
}

.close:hover {
  cursor: pointer;
}

