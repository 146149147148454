.login.head-bg {
  background: url(../../../images/login-bg.jpg) no-repeat center bottom/cover;
}

.login-block {
  background: rgba(255, 255, 255, 0.95);
  padding: 6rem;
  align-self: center;
  box-shadow: 0px 14px 42px -5px rgba(0, 0, 0, 0.5);
  border-radius: 1.6rem;
  margin-top: -10rem;
  margin-bottom: 10rem;
  z-index: 6;
}

.login-block h1 {
  margin-bottom: 2rem;
  margin-top: 0;
}

.alert.alert-danger {
  background: none;
  border: 0;
  color: #e96571;
  margin: 2rem auto;
  text-align: center;
}

.Login__options {
  margin: 2rem auto 0 auto;
  border-top: solid 0.1rem rgba(42, 55, 67, 0.1);
  padding-top: 2rem;
}

.Login__options a {
  color: #2a3743;
}

.Login__options a:first-child {
  border-right: solid 0.1rem #2a3743;
  padding-right: 1rem;
  margin-right: 1rem;
  color: #43d9b2;
}

.Login__img {
  width: 2rem;
  margin-right: 1rem;
}

.Login__google {
  background: #e96571;
  color: #fff;
  border-radius: 1.6rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  font-size: 1.6rem;
  transition: all 0.3s ease;
}

.Login__google:hover {
  cursor: pointer;
  filter: brightness(1.1);
}

.Login__form {
  border-bottom: solid 0.1rem rgba(42, 55, 67, 0.1);
  padding-bottom: 2rem;
}

@media (max-width: 576px) {
  .login-block {
    padding: 3rem;
  }
  .options a:first-child {
    border-right: 0;
  }
}
