.HistModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(67, 217, 178, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
}

.HistModal__body {
  background: #fff;
  padding: 3rem;
  height: 90vh;
  overflow: scroll;
  position: relative;
  border: solid 0.2rem #43d9b2;
  display: flex;
  align-items: center;
  border-radius: 0.8rem;
}

.HistModal__body h1 {
  position: relative;
}

.HistModal__body h1 span {
  margin-top: 0.8rem;
  color: #fff;
  font-size: 1.4rem;
  display: table;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  margin-bottom: 3rem;
  background: #43d9b2;
}

.HistModal__body h1:before {
  content: '';
  width: 6rem;
  height: 6rem;
  background: url(../../../../images/biopsy.svg) no-repeat center center;
  float: left;
  margin-right: 1rem;
}

.label {
  display: block;
}

.react-datetime-picker {
  width: 100%;
  border: solid 0.1rem rgba(66, 137, 221, 0.5);
  border-radius: 0.8rem;
  padding: 0.5rem;
}

.react-datetime-picker .react-datetime-picker__wrapper {
  border: none;
}

.react-datetime-picker .react-datetime-picker__calendar {
  z-index: 2;
}

.close {
  position: absolute;
  font-size: 2rem;
  top: 2rem;
  right: 2rem;
  width: 3rem;
  height: 3rem;
  font-weight: 300;
  background: url(../../../../images/close.svg) no-repeat center center/contain;
  z-index: 5;
}

.close:hover {
  cursor: pointer;
}
