@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
html {
  font-size: 10px;
}

body {
  font-size: 1.4rem;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: #2a3743;
}

body strong {
  font-weight: 700;
}

body .light {
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

.gray-bg {
  background: #f3f6f8;
}

.loader {
  background: url(../images/loader.gif) no-repeat left center/contain;
  padding-left: 8rem;
  height: 8rem;
  display: flex;
  align-items: center;
}

.loader span {
  color: #2a3743;
  margin-left: 1rem;
}
