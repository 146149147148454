.EditCaseModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
}

.EditCaseModal__body {
  background: #fff;
  padding: 6rem 3rem;
  overflow: scroll;
  position: relative;
  border: solid 0.2rem #43d9b2;
  border-radius: 0.8rem;
}

.EditCaseModal__body h1 {
  position: relative;
  margin-bottom: 2rem;
}

.close {
  position: absolute;
  font-size: 2rem;
  top: 2rem;
  right: 2rem;
  width: 3rem;
  height: 3rem;
  font-weight: 300;
  background: url(../../../../images/close.svg) no-repeat center center/contain;
  z-index: 5;
}

.close:hover {
  cursor: pointer;
}
