.patients .message {
  margin-top: 6rem;
  padding: 1rem 2rem;
  background: rgba(170, 138, 207, 0.3);
  border: solid 0.1rem #aa8acf;
  color: #aa8acf;
}

.patients .custom-info .patient-name {
  top: 0;
}

.patients .custom-info .card .card-body {
  padding: 4rem 0 0 0;
  font-size: 1.4rem;
}

.patients .custom-info .card .card-body .card-text .patient-dni {
  border-left: solid 0.1rem #fff;
  color: #fff;
  margin-left: 0.8rem;
  padding-left: 0.8rem;
}

.patients .email-icon,
.patients .phone-icon,
.patients .address-icon,
.patients .calendar-icon {
  position: relative;
  padding: 0 1rem;
}

.patients .email-icon:before,
.patients .phone-icon:before,
.patients .address-icon:before,
.patients .calendar-icon:before {
  content: '';
  width: 2rem;
  height: 5rem;
  margin-right: 1.6rem;
  float: left;
}

.patients .email-icon:before {
  background: url(../../images/email.svg) no-repeat center center/contain;
}

.patients .phone-icon:before {
  background: url(../../images/phone.svg) no-repeat center center/contain;
}

.patients .address-icon:before {
  background: url(../../images/address.svg) no-repeat center center/contain;
}

.patients .calendar-icon {
  border-top: solid 0.1rem #43d9b2;
  padding: 1rem;
  background: rgba(67, 217, 178, 0.16);
}

.patients .calendar-icon:before {
  background: url(../../images/calendar.svg) no-repeat center center/contain;
}

.patients .calendar-icon .next-date {
  position: absolute;
  top: -1rem;
  left: 0;
  background: #c0c8cf;
  padding: 0.3rem 1rem 0.3rem 2rem;
  border-radius: 0 1rem 1rem 0;
  color: #fff;
  font-size: 1.2rem;
}

.patients .card-footer {
  padding: 0;
}

.patients .card-footer .purple-bg {
  background: #aa8acf;
  justify-content: center;
  display: flex;
  margin-bottom: 0;
}

.patients .card-footer .purple-bg .secondary {
  background: #aa8acf;
}

.patients .icons [class*='-icon'] {
  border: 0;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 4rem;
  margin-right: 1rem;
}

.patients .icons [class*='-icon']:hover {
  opacity: 0.5;
}

.patients .icons .edit-icon {
  background: #43d9b2 url(../../images/edit_white.svg) no-repeat center center/contain;
  background-size: 1.2rem;
}

.patients .icons .delete-icon {
  background: #e96571 url(../../images/delete_white.svg) no-repeat center center/contain;
  margin-top: 0.5rem;
  background-size: 1.2rem;
}

.patients .icons .position-absolute {
  top: 0.5rem;
  right: 0.5rem;
}

.primary {
  text-decoration: none;
}

.primary:hover {
  color: #fff;
  font-weight: 700;
}
