.primary, .secondary {
  border: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 3.2rem;
  border-radius: 4rem;
  font-size: 1.4rem;
}

.primary {
  background: #43d9b2;
}

.secondary {
  background: #e96571;
}
