.Header__navbar {
  font-weight: 400;
  align-items: center;
  width: 100%;
  padding: 0;
  padding-right: 2rem;
  margin: 2rem auto;
  display: flex;
  justify-content: space-between;
}

.Header__navbar .navbar-nav {
  float: right;
}

.Header__navbar .logout {
  font-weight: 700;
  position: relative;
  display: flex;
  align-items: center;
  color: #43d9b2 !important;
}

.Header__navbar .logout:after {
  content: '';
  position: absolute;
  right: -2.4rem;
  background: url(../../images/exit.svg) no-repeat center center;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-left: 1rem;
  transition: all 200ms ease-in-out;
}

.Header__navbar .logout:hover:after {
  margin-left: 1.6rem;
  right: -2.8rem;
}

.Header__navbar .user-logs {
  border-left: 0;
}

.Header__navbar .header-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Header__navbar .header-user .user-hi {
  color: #fff;
  border-left: solid 0.1rem #4289dd;
  padding-left: 2rem;
}

.Header__navbar .navbar-brand {
  padding: 3rem 1rem 3rem 3rem;
}

.Header__navbar .navbar-brand a {
  text-decoration: none;
}

.Header__navbar .navbar-brand h1 {
  color: #2a3743;
}

.Header__navbar .navbar-brand .logo-header {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 3rem;
  text-transform: uppercase;
  color: #43d9b2;
}

.Header__navbar .navbar-brand .logo-header:before {
  content: '';
  display: inline-block;
  background: url(../../images/icon-logo.svg) no-repeat center center/contain;
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 1.6rem;
}

.Header__navbar .navbar-brand .logo-header:after {
  content: 'Oliver';
  display: block;
  font-weight: 300;
  color: #4289dd;
  margin-left: 0.8rem;
  position: absolute;
  left: 4rem;
  top: 2.4rem;
}

.Header__navbar .navbar-collapse {
  justify-content: flex-end;
}

.Header__navbar .navbar-collapse ul {
  justify-content: flex-end;
}

.Header__navbar .nav-item {
  margin-right: 3rem;
  display: inline-block;
}

.Header__navbar .nav-item a {
  color: rgba(42, 55, 67, 0.6);
  transition: all 200ms ease;
}

.Header__navbar .nav-item a.login {
  background: #43d9b2;
  color: #fff;
  padding: 0.8rem 3.2rem;
  border-radius: 4rem;
  position: relative;
  margin-left: 3rem;
}

.Header__navbar .nav-item a.login:hover, .Header__navbar .nav-item a.login.active {
  color: #fff;
  background: #4289dd;
}

.Header__navbar .nav-item a.login.active {
  pointer-events: none;
}

.Header__navbar .nav-item a:hover {
  color: #4289dd;
}

.Header__navbar .nav-item .active {
  color: #4289dd;
}

@media (max-width: 576px) {
  .Header__navbar {
    padding: 0;
    padding-right: 0;
    margin-bottom: 0;
    position: relative;
  }
  .Header__navbar .navbar-brand {
    padding: 0 0 4rem 2rem;
  }
  .Header .resp_login {
    color: #43d9b2;
    text-decoration: none;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: #43d9b2 url(../../images/sign-in.svg) no-repeat center center/2rem;
    display: block;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
