.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal .icon:before {
  content: '';
  width: 6rem;
  height: 6rem;
  background: url(../../images/biopsy.svg) no-repeat center center;
  float: left;
  margin-right: 1rem;
}

.modal .b-number {
  color: #fff;
  font-weight: 700;
  background: #43d9b2;
  float: left;
  padding: 0 1.6rem;
  border-radius: 4rem;
}

.modal .b-number,
.modal .b-date {
  font-size: 1.6rem;
}

.modal .patient-info {
  background: #43d9b2;
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 4rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-size: 1.6rem;
}

.modal hr {
  margin-top: 2rem;
}

.modal .discipline {
  font-weight: 400;
  color: rgba(51, 51, 51, 0.6);
  font-size: 1.8rem;
}

.modal .modal-body {
  background: #fff;
  padding: 3rem;
  border-radius: 0.8rem;
  position: relative;
  border: solid 0.2rem #43d9b2;
  flex: 0 0 auto;
}

.modal .modal-body button {
  margin: 3rem auto 0 auto;
}

.modal .modal-body span {
  color: #43d9b2;
}

.modal .modal-body span h1 {
  color: #2a3743;
}

.modal .modal-body .btn-row {
  display: flex;
  justify-content: center;
}

.modal .modal-body .btn-row .primary {
  display: inline-block;
  margin: 2rem auto 0 auto;
  transition: all 200ms ease-in-out;
}

.modal .modal-body .btn-row .primary:hover {
  color: #fff;
  text-decoration: none;
  background: rgba(67, 217, 178, 0.8);
}

.modal .close {
  position: absolute;
  font-size: 2rem;
  top: 2rem;
  right: 2rem;
  width: 3rem;
  height: 3rem;
  font-weight: 300;
  background: url(../../images/close.svg) no-repeat center center/contain;
  z-index: 5;
}

.modal .close:hover {
  cursor: pointer;
}

.modal .downloadPDF {
  position: relative;
  padding-left: 5rem;
}

.modal .downloadPDF:before {
  content: '';
  background-image: url(../../images/download.svg);
  display: inline-block;
  margin-right: 1rem;
  position: absolute;
  width: 3rem;
  height: 3rem;
  background-size: 2rem 2rem;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  left: 1rem;
  top: 0.5rem;
  border-radius: 50%;
}

.modal .edit,
.modal .delete {
  display: flex;
  justify-content: center;
  transition: all 200ms ease-in-out;
  position: relative;
}

.modal .edit:before,
.modal .delete:before {
  content: '';
  display: inline-block;
  margin-right: 1rem;
  position: absolute;
  width: 3rem;
  height: 3rem;
  background-size: 2rem 2rem;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  left: 1rem;
  border-radius: 50%;
}

.modal .edit:before {
  background-image: url(../../images/edit.svg);
}

.modal .edit:hover {
  color: #fff;
  text-decoration: none;
  background: rgba(67, 217, 178, 0.8);
}

.modal .delete:before {
  background-image: url(../../images/delete.svg);
}

.modal .delete:hover {
  color: #fff;
  text-decoration: none;
  background: rgba(233, 101, 113, 0.8);
}

.modal hr {
  margin-bottom: 1rem;
}

@media (max-width: 576px) {
  .modal .modal-body {
    padding: 2rem 1rem;
  }
  .modal .icon:before {
    width: 3rem;
    height: 3rem;
  }
  .modal .patient-info {
    color: #43d9b2;
    border-radius: 0;
    background: #fff;
    border-top: solid 0.1rem #43d9b2;
    border-bottom: solid 0.1rem #43d9b2;
  }
  .modal .patient-info .text-center {
    text-align: left !important;
  }
}
